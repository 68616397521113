<template>
  <div class="main" data-title="基础数据配置" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">基础数据配置</h2>
      </div>
      <div class="head-search"></div>
      <div class="head-tabs">
        <div @click="$router.push({name: 'database-config'})" class="tab curr">基础数据配置</div>
        <div @click="$router.push({name: 'database-custom'})" class="tab">自定义字段</div>
        <!-- <div @click="$router.push({name: 'database-reviewer'})" class="tab">战败审核人</div> -->
        <div @click="$router.push({name: 'database-kpi'})" class="tab">销售目标设置</div>
      </div>
    </div>

    <el-card class="main-content" shadow="hover">
      <div class="menu-box">
        <config-menu @setConfig="setConfig" class="left-menu"></config-menu>
        <div class="right-main">
          <div class="head">
            <div class="head-title">促进结果</div>
            <div class="head-btns">
              <el-button
                @click="add('ruleForm')"
                class="btn"
                icon="el-icon-edit"
                type="primary"
                v-has="'cfgdata-create'"
              >新建</el-button>
            </div>
          </div>

          <div class="body">
            <div class="table-wrapper">
              <el-table :data="cfgData" class="table" style="width: 100%">
                <el-table-column label="客户级别" prop="f2">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">{{$store.state.cfgData[scope.row.f2][0]}}</span>
                    <span style="opacity: .5;" v-else>{{$store.state.cfgData[scope.row.f2][0]}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="促进结果">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1">{{scope.row.f1}}</span>
                    <span style="opacity: .5;" v-else>{{scope.row.f1}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="备注" prop="memo"></el-table-column>
                <el-table-column fixed="right" label="操作" width="180px">
                  <template slot-scope="scope">
                    <el-button
                      @click="edit(scope.$index, scope.row)"
                      size="small"
                      type="text"
                      v-has="'cfgdata-edit'"
                    >修改</el-button>
                    <span class="jue-divider" v-has="'cfgdata-edit'"></span>

                    <el-button
                      @click="changeItem(scope.$index, scope.row)"
                      class="btn-delete no-bg"
                      size="small"
                      type="text"
                      v-if="scope.row.status == 1"
                    >停用</el-button>
                    <el-button
                      @click="changeItem(scope.$index, scope.row)"
                      size="small"
                      type="text"
                      v-if="scope.row.status == 2"
                    >启用</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 数量编辑 -->
    <el-dialog :title="dialogType+'车型'" :visible.sync="dialogVisible">
      <el-form
        :model="ruleForm"
        :rules="rules"
        class="ruleForm"
        label-width="100px"
        ref="ruleForm"
        status-icon
      >
        <!-- <el-form-item label="车辆型号:" prop="f1">
          <el-input suffix-icon="el-icon-edit" v-model="ruleForm.f1"></el-input>
        </el-form-item>-->

        <el-form-item label="客户级别:" prop="f1">
          <el-select
            allow-create
            default-first-option
            filterable
            placeholder="请选择"
            style="width: 100%"
            v-model="ruleForm.f2"
          >
            <el-option
              :key="index"
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in demandCarType"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="促进结果:" prop="f2">
          <el-input suffix-icon="el-icon-edit" v-model="ruleForm.f1"></el-input>
        </el-form-item>
        <el-form-item label="排序:">
          <el-input-number :max="100" :min="1" controls-position="right" v-model="ruleForm.order"></el-input-number>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <el-tooltip effect="dark" placement="right">
              <div slot="content">
                该选项一经打开，后期只能修改，不可删除。
                <br />请谨慎操作！
              </div>
              <i class="el-icon-question icon-help"></i>
            </el-tooltip>固定选项:
          </span>
          <el-switch
            :active-value="1"
            :disabled="ruleForm.isReadonly"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="#DCDFE6"
            v-model="ruleForm.readonly"
          ></el-switch>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input :rows="2" placeholder="请输入" type="textarea" v-model="ruleForm.memo"></el-input>
        </el-form-item>
      </el-form>

      <span class="dialog-footer" slot="footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button @click="submitForm('ruleForm')" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ConfigMenu from '@/components/ConfigMenu.vue'
import { cfgById } from '@/unit/unit.js'
export default {
  components: {
    ConfigMenu,
  },
  data() {
    return {
      dialogVisible: false, //控制编辑对话框显示与隐藏
      dialogType: '新建', // 新建 | 编辑
      ruleForm: {
        isReadonly: false,
        sys_id: '',
        key: '',
        f1: '',
        f2: '',
        f3: '',
        order: '1',
        readonly: '2', //1是  2否，不可删除该选项
        memo: '',
      },
      rules: {
        f1: [{ required: true, message: '必需填写', trigger: 'blur' }],
        f2: [{ required: true, message: '必需填写', trigger: 'blur' }],
      },
      cfgData: [],
      jsonData: [],
      createOptions: [],
      demandCarType: [],
    }
  },
  mounted() {
    this.getCfgdataList()
    this.getAttribute()
  },
  methods: {
    getCfgData(cid) {
      return cfgById(cid)
    },
    setConfig(title) {
      //设置标题
      this.keyTitle = title
      //重新获取数据
      this.getCfgdataList()
    },

    //获取现在车型说明
    getAttribute() {
      this.$axios
        .post('/cfgdata/lists', {
          key: 'Result',
        })
        .then((res) => {
          if (res.data.code == 200) {
            res.data.data[0].list.map((item, index) => {
              if(item.f2 == ''){
                delete res.data.data[0].list[index]
              }
            })
            this.cfgData = res.data.data[0].list
          }
        })
    },

    //获取现有车型
    getCfgdataList() {
      this.$axios
        .post('/cfgdata/lists', {
          key: 'customerLevel',
        })
        .then((res) => {
          if (res.data.code == 200) {
            if (res.data.data.length > 0) {
              let tmp_Options = []
              res.data.data[0].list.map((item) => {
                // tmp_Options.push({ value: item.f1, label: item.f1 })
                this.demandCarType.push({ value: item.sys_id, label: item.f1 })
              })
            } else {
              this.demandCarType = []
            }
          }
        })
    },

    getNew(old) {
      let temp = old.reduce((acc, cur) => {
        if (acc[cur.f1]) {
          acc[cur.f1].push(cur.f2)
        } else {
          acc[cur.f1] = [cur.f2]
        }
        return acc
      }, {})

      return Object.keys(temp).map((key) => {
        return {
          a: temp[key],
          b: key,
        }
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.key = 'Result'
          if (this.dialogType == '新建') {
            this.$axios.post('/cfgdata/create', this.ruleForm).then((res) => {
              if (res.data.code == '200') {
                this.getAttribute()
                this.$message.success(res.data.msg)
                this.dialogVisible = false
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }

          if (this.dialogType == '编辑') {
            this.$axios.post('/cfgdata/edit', this.ruleForm).then((res) => {
              if (res.data.code == '200') {
                this.getAttribute()

                this.$message.success(res.data.msg)
                this.dialogVisible = false
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }

          this.cfgData.sort()
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      // Object.assign(this.ruleForm, this.$options.data().ruleForm)
      this.dialogVisible = false
    },
    changeItem(index, item) {
      let text = item.status == 1 ? '停用' : '启用'
      let val = item.status == 1 ? 2 : 1
      this.$confirm('此操作将 ' + text + item.f1 + ' , 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post('/cfgdata/remove', {
              sys_id: item.sys_id,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getCfgdataList()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    add(formName) {
      this.dialogVisible = true
      this.dialogType = '新建'

      this.ruleForm.key = ''
      this.ruleForm.sys_id = ''
      this.ruleForm.f1 = ''
      this.ruleForm.f2 = ''
      this.ruleForm.f3 = ''
      this.ruleForm.order = 1
      this.ruleForm.readonly = 2
      this.ruleForm.memo = ''
    },
    edit(index, item) {
      if (item.readonly == 1) {
        this.ruleForm.isReadonly = true
      }

      this.ruleForm.key = item.key
      this.ruleForm.sys_id = item.sys_id
      this.ruleForm.f1 = item.f1
      this.ruleForm.f2 = parseInt(item.f2)
      this.ruleForm.f3 = item.f3
      this.ruleForm.order = item.order
      this.ruleForm.readonly = item.readonly
      this.ruleForm.memo = item.memo
      this.dialogType = '编辑'
      this.dialogVisible = true
    },
  },
}
</script>
<style lang="less" scoped>
.main-content {
  /deep/ .el-card__body {
    padding-left: 0;
    padding-right: 0;
  }
}
.menu-box {
  display: flex;
  .left-menu {
    width: 224px;
  }
  .right-main {
    margin: 8px 40px;
    flex: 1;
    .head {
      display: flex;
      margin-bottom: 24px;
      .head-title {
        font-weight: 500;
        font-size: 20px;
        flex: 1;
      }
      .head-btns {
        display: flex;
        align-items: center;
        i.el-icon-check {
          font-size: 20px;
          color: #409eff;
        }
      }
    }
  }
}

.ruleForm {
  padding: 0 80px;
}
</style>